import { useDispatch, useSelector } from 'react-redux'
import { ImCross } from 'react-icons/im'
import {
  useDeleteZeroOrderMutation,
  useGetClientDataQuery,
  useGetClientOrdersQuery,
} from '../../../redux/clientData/apiClientData'
import Loader from '../../loader/Loader'
import styles from './Info.module.css'
import {
  selectHideIncomePrices,
  selectShowReferencePrice,
  setHideIncomePrices,
  setShowReferencePrice,
} from '../../../redux/searchSpares/sparesSlice'
import { useEffect, useState } from 'react'
import ModalOrdersHistory from '../../modals/ordersHistory/ModalOrdersHistory'
import ModalPaymentHistory from '../../modals/paymentHistory/ModalPaymentHistory'
import { toast } from 'react-toastify'
import ModalInstruction from '../../modals/instruction/ModalInstruction'

const Info = ({ rerender }) => {
  const [isShowOrdersHistory, setIsShowOrdersHistory] = useState(false)
  const [isShowPaymentHistory, setIsShowPaymentHistory] = useState(false)
  const [isShowInstruction, setIsShowInstruction] = useState(false)

  const dispatch = useDispatch()
  const hideIncomePrices = useSelector(selectHideIncomePrices)
  const showReferencePrice = useSelector(selectShowReferencePrice)

  const {
    data: ordersData,
    isLoading: isLoadingOrdersData,
    refetch,
  } = useGetClientOrdersQuery()

  const { data: clientData, isLoading: isLoadingClientData } =
    useGetClientDataQuery()

  const [deleteZeroOrder, { isLoading: isLoadingDeleteZeroOrder }] =
    useDeleteZeroOrderMutation()

  useEffect(() => {
    refetch()
  }, [refetch, rerender])

  const handleHideIncomePrices = () => {
    dispatch(setHideIncomePrices(!hideIncomePrices))
  }
  const handleShowReferencePrice = () => {
    dispatch(setShowReferencePrice(!showReferencePrice))
  }

  const countTotalOrderedSum = (orders) => {
    return orders
      .reduce((acc, order) => {
        return acc + order.confirmedQty * order.price
      }, 0)
      .toFixed(2)
  }
  const countTotalConfirmedSum = (orders) => {
    return orders
      .reduce((acc, order) => {
        return acc + order.qty * order.price
      }, 0)
      .toFixed(2)
  }

  const handleDeleteZeroOrder = async (requestID, spareID) => {
    try {
      await deleteZeroOrder({ requestID, spareID })
      refetch()
    } catch (error) {
      toast.error('Сталась помилка...')
    }
  }

  if (isLoadingOrdersData || isLoadingDeleteZeroOrder || isLoadingClientData)
    return <Loader />

  return (
    <div className={styles.infoPage}>
      {isShowOrdersHistory && (
        <ModalOrdersHistory setIsShowOrdersHistory={setIsShowOrdersHistory} />
      )}
      {isShowPaymentHistory && (
        <ModalPaymentHistory
          setIsShowPaymentHistory={setIsShowPaymentHistory}
        />
      )}
      {isShowInstruction && (
        <ModalInstruction setIsShowInstruction={setIsShowInstruction} />
      )}
      <div className={styles.btnContainer}>
        <button
          onClick={() => setIsShowOrdersHistory(true)}
          className={styles.generalBtn}
        >
          ІСТОРІЯ ПОКУПОК
        </button>
        <button
          onClick={() => setIsShowPaymentHistory(true)}
          className={styles.generalBtn}
        >
          ІСТОРІЯ ОПЛАТ
        </button>
        <button
          onClick={() => setIsShowInstruction(true)}
          className={styles.generalBtnInst}
        >
          ІНСТРУКЦІЯ ПО САЙТУ
        </button>
        <input
          className={styles.checkbox}
          onChange={handleHideIncomePrices}
          type="checkbox"
          checked={hideIncomePrices}
        />{' '}
        <span className={styles.hideIncomePricesText}>Сховати вхідні ціни</span>
        {clientData.currency === 'eur' && (
          <>
            <input
              className={styles.checkbox}
              onChange={handleShowReferencePrice}
              type="checkbox"
              checked={showReferencePrice}
            />
            <span className={styles.hideIncomePricesText}>
              Показати ціну референс
            </span>
          </>
        )}
      </div>
      <div className={styles.pendingOrdersContainer}>
        <h3>ЗАМОВЛЕНІ ПОЗИЦІЇ</h3>{' '}
        {ordersData?.pendingOrders?.length > 0 &&
          countTotalOrderedSum(ordersData.pendingOrders) > 0 && (
            <span className={styles.orderedSumContainer}>
              Разом: {countTotalOrderedSum(ordersData.pendingOrders)}
            </span>
          )}
        {ordersData?.pendingOrders?.length > 0 ? (
          <table className={styles.pendingOrderTable}>
            <thead>
              <tr>
                <th>Бренд</th>
                <th>Номер</th>
                <th>В замовленні, шт</th>
                <th>Підтвeрджено, шт</th>
                <th>Ціна</th>
                <th>Опис</th>
                <th>Дата замовлення</th>
                <th>Статус</th>
                <th>Видалити</th>
              </tr>
            </thead>
            <tbody>
              {ordersData?.pendingOrders?.map((order, i) => (
                <tr
                  key={i}
                  className={
                    order.confirmedQty > 0
                      ? styles.orderIsConfirmed
                      : order.orderedQty > 0
                      ? styles.orderIsPending
                      : styles.orderIsRejected
                  }
                >
                  <td>{order.brand}</td>
                  <td>{order.code}</td>
                  <td>{order.orderedQty}</td>
                  <td>{order.confirmedQty}</td>
                  <td>{order.price}</td>
                  <td>{order.description}</td>
                  <td>{order.date}</td>
                  <td>
                    {order.confirmedQty > 0
                      ? 'В дорозі'
                      : order.orderedQty > 0
                      ? 'В обробці'
                      : 'Відмовлено'}
                  </td>
                  <td className={styles.deleteTd}>
                    {order.confirmedQty > 0 ? null : order.orderedQty >
                      0 ? null : (
                      <ImCross
                        onClick={() =>
                          handleDeleteZeroOrder(order.requestID, order.spareID)
                        }
                        className={styles.deleteBtn}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h3 className={styles.noOrdersText}>Замовлень немає</h3>
        )}
      </div>
      <div className={styles.pendingOrdersContainer}>
        <h3>ПОЗИЦІЇ ГОТОВІ ДО ВІДВАНТАЖЕННЯ</h3>
        {ordersData?.readyOrders?.length > 0 &&
          countTotalConfirmedSum(ordersData?.readyOrders) > 0 && (
            <span className={styles.orderedSumContainer}>
              Разом: {countTotalConfirmedSum(ordersData?.readyOrders)}
            </span>
          )}
        {ordersData?.readyOrders?.length > 0 ? (
          <table className={styles.pendingOrderTable}>
            <thead>
              <tr>
                <th>Бренд</th>
                <th>Номер</th>
                <th>Опис</th>
                <th>Кількість</th>
                <th>Ціна</th>
                <th>Дата готовності</th>
              </tr>
            </thead>
            <tbody>
              {ordersData?.readyOrders?.map((order, i) => (
                <tr key={i}>
                  <td>{order.brand}</td>
                  <td>{order.code}</td>
                  <td>{order.description}</td>
                  <td>{order.qty}</td>
                  <td>{order.price}</td>
                  <td>{order.arrivalDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h3 className={styles.noOrdersText}>Замовлень немає</h3>
        )}
      </div>
    </div>
  )
}

export default Info
