import React from 'react'
import { ImCross } from 'react-icons/im'
import styles from './ModalInstruction.module.css'
import image1 from '../../../img/instruction/1.png'
import image2 from '../../../img/instruction/2.png'
import image3 from '../../../img/instruction/3.png'
import image4 from '../../../img/instruction/4.png'
import image5 from '../../../img/instruction/5.png'
import image6 from '../../../img/instruction/6.png'
import image7 from '../../../img/instruction/7.png'
import image8 from '../../../img/instruction/8.png'
import image9 from '../../../img/instruction/9.png'
import image10 from '../../../img/instruction/10.png'
import image11 from '../../../img/instruction/11.png'
import image12 from '../../../img/instruction/12.png'
import image13 from '../../../img/instruction/13.png'

const ModalInstruction = ({ setIsShowInstruction }) => {
  return (
    <div
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          setIsShowInstruction(false)
        }
      }}
      className="overlayCont"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={styles.modalContainer}
      >
        <div className={styles.modalHeader}>
          <div>Інструкція</div>
          <div
            className={styles.closeIcon}
            onClick={() => setIsShowInstruction(false)}
          >
            <ImCross />
          </div>
        </div>
        <div className={styles.modalContent}>
          <section>
            <h2>Загальні дані</h2>
            <ol>
              <li>
                Для того, щоб почати роботу, необхідно пробити у нас номер
                деталі. Для цього введіть потрібний вам номер деталі. Ми введемо
                фільтр <strong>OX188D</strong> та натиснемо “Enter”.
              </li>
              <li>
                Нам виведе список брендів, для яких підходить цей номер.
                Вибираємо бренд <strong>"KNECHT"</strong>.
              </li>
              <div className={styles.imageContainer}>
                <img src={image1} alt="instruction" />
              </div>
              <li>
                Далі нам відобразиться таблиця зі списком деталей. У ній ви
                побачите бренд, номер та опис деталі для швидкої орієнтації.
                Далі йде роздрібна ціна (ліворуч), а потім ваша (праворуч). У
                колонці показано, скільки деталей є на складі та скільки зараз
                доступно для покупки.
              </li>
              <div className={styles.imageContainer}>
                <img src={image2} alt="instruction" />
              </div>
              <li>
                Для того, щоб купити деталь, просто натисніть на потрібний рядок
                і оберіть потрібну кількість.
              </li>
              <div className={styles.imageContainer}>
                <img src={image3} alt="instruction" />
              </div>
              <li>
                Для того, щоб зняти деталь з резерву, необхідно зателефонувати
                менеджеру.
              </li>
              <li>
                Таблиця деталей складається з 3 секцій:
                <ul>
                  <li>
                    <strong>Наявність на складі</strong> (блакитна секція) –
                    відображаються всі позиції, які є аналогами (замінниками)
                    для запитаної позиції та які є у нас.
                  </li>
                  <li>
                    <strong>Запитаний артикул</strong> (жовта секція) –
                    відображається лише запитана позиція, якої у нас немає, але
                    яку ми можемо привезти на замовлення.
                  </li>
                  <li>
                    <strong>Аналоги для запитаного артикула</strong> (сіра
                    секція) – відображаються всі аналоги (замінники), які ми
                    можемо привезти на замовлення.
                  </li>
                </ul>
              </li>
              <li>
                Будьте дуже обережні та перевіряйте правильність аналогів!!!
              </li>
              <li>
                Для того, щоб дізнатися доступну на замовлення кількість у
                жовтій та сірій секціях, зверніть увагу на колонку “Замовлення”.
                У колонці “Термін” вказано кількість днів, через яку ця деталь
                може бути у нас. Для більш детальної інформації дивіться опис
                деталей у дужках. Щоб отримати додаткову інформацію про
                прибуття, натисніть на термін доставки (виділений зеленим).
              </li>
              <div className={styles.imageContainer}>
                <img src={image4} alt="instruction" />
              </div>
              <li>
                Для зручного пошуку на нашому сайті можете скористатися
                комбінацією клавіш <strong>Ctrl + F</strong>. Вона відкриє
                вікно, в яке можна ввести шукане значення – якщо воно є на
                сторінці, воно буде підсвічено. Наприклад, якщо у великому
                переліку олії потрібно знайти ARAL, натисніть{' '}
                <strong>Ctrl+F</strong> та введіть “ARAL”.
              </li>
              <div className={styles.imageContainer}>
                <img src={image5} alt="instruction" />
              </div>
            </ol>
          </section>

          <section>
            <h2>Робота з існуючими замовленнями</h2>
            <ol>
              <li>
                Натисніть кнопку “Інфо”, щоб перейти до меню роботи із
                замовленнями.
              </li>
              <li>
                Тут будуть відображені 2 таблиці:{' '}
                <strong>"Замовлені позиції"</strong> та{' '}
                <strong>"Виконані замовлення"</strong>. У замовлених позиціях
                показані позиції, яких у нас немає та які ви хочете замовити. У
                виконаних – позиції, які вже є на складі. Коли замовлена деталь
                приїжджає на склад, вона переміщується з верхньої частини в
                нижню.
              </li>
              <div className={styles.imageContainer}>
                <img src={image6} alt="instruction" />
              </div>
              <li>
                У замовлених позиціях може бути 3 кольори: червоний, зелений та
                сірий. Червоний означає, що цієї позиції немає у постачальника,
                зелений – позиція замовлена та очікується її прибуття, сірий –
                позиція ще обробляється відділом замовлень або рідко, що позиції
                немає у постачальника.
              </li>
              <div className={styles.imageContainer}>
                <img src={image7} alt="instruction" />
              </div>
              <li>
                Щоб швидко зрозуміти, що позиції у постачальника немає,
                подивіться, чи є червоний хрестик у колонці “Удал”.
              </li>
              <div className={styles.imageContainer}>
                <img src={image8} alt="instruction" />
              </div>
              <li>
                Для того щоб товар зібрали або відправили, зателефонуйте
                менеджеру або на склад. Також існує можливість налаштувати
                автоматичний друк товару, але це можна зробити через менеджера.
              </li>
            </ol>
          </section>

          <section>
            <h2>Ціни</h2>
            <ol>
              <li>
                Можна приховати ваші ціни та відображати лише роздрібну ціну на
                сайті. Для цього натисніть на вкладку <strong>"Інфо"</strong>.
              </li>
              <li>
                Поставте галочку навпроти{' '}
                <strong>"Приховати вхідні ціни"</strong>.
              </li>
              <div className={styles.imageContainer}>
                <img src={image9} alt="instruction" />
              </div>
              <li>
                Тепер пробийте номер ще раз, і відображатиметься лише роздрібна
                ціна.
              </li>
              <div className={styles.imageContainer}>
                <img src={image10} alt="instruction" />
              </div>
            </ol>
          </section>

          <section>
            <h2>Оплати</h2>
            <ol>
              <li>
                Суму боргу та прострочення наразі можна побачити зверху сайту.
              </li>
              <li>
                Для більш детальної інформації натисніть на вкладку{' '}
                <strong>"План оплат"</strong>, де ви побачите суми та строки, до
                яких їх необхідно передати.
              </li>
              <div className={styles.imageContainer}>
                <img src={image11} alt="instruction" />
              </div>
            </ol>
          </section>

          <section>
            <h2>Пошук певних типів деталей та загальні правила</h2>
            <ul>
              <li>
                Коли пробиваєте номер деталі, не має значення, чи пишете ви
                великі чи маленькі літери. Наприклад, vOda = VODA = voda = Voda
                = vODA.
              </li>
              <li>
                При введенні номерів усі символи не мають значення. Наприклад,{' '}
                <em>15-41286-01</em> можна вводити як 154128601, 15-4128601,
                1541286.01, 15/4.1.2.8.6.01, 1 54 1286 0 1, 15-4128/6 01.
              </li>
              <li>
                Якщо ви не знаєте повного номера, можна вводити не повну
                послідовність – програма запропонує кілька варіантів закінчення
                номерів. Наприклад, ввівши <strong>6134250</strong>, програма
                видасть 3 варіанти товщин прокладок, які відрізняються лише
                останніми цифрами.
              </li>
              <div className={styles.imageContainer}>
                <img src={image12} alt="instruction" />
              </div>
              <li>Щоб знайти дистильовану воду, введіть “voda” або “вода”.</li>
              <li>
                Для пошуку гальмівних трубок введіть довжину трубки в мм з
                кінцевим “мм” або “mm”. Наприклад:
                <ul>
                  <li>1000мм, 1000 mm – покаже трубку довжиною 1 метр.</li>
                  <li>100мм, 100 mm – покаже трубку в 10 см.</li>
                  <li>
                    Якщо потрібний розмір не знаходиться, округліть його до
                    найближчої сотні (наприклад, 3750 мм → 3800 мм).
                  </li>
                </ul>
              </li>
              <li>
                Для пошуку перехідників на гальмівні трубки введіть
                “Перехідник”. Позначення <strong>10/1.25 мм</strong> означає, що
                діаметр різьблення становить 10 мм, а крок – 1,25 мм.
                <div className={styles.imageContainer}>
                  <img src={image13} alt="instruction" />
                </div>
              </li>
              <li>
                Для пошуку гальмівної рідини введіть DOT4 і в колонці бренд
                виберіть “Тип (Рідини)”. Також можна додатково вводити літраж
                (0.25 л, 0.5 л, 1 л, 5 л).
              </li>
              <li>
                Для пошуку хомутів введіть менший розмір діаметра, до якого може
                стягнутися хомут (наприклад, 10mm, 10мм, 8 мм, 20mm) та виберіть
                “Розмір (Хомути)”.
              </li>
              <li>
                Для пошуку гофри глушника введіть слово “гофра” – з’явиться
                список всіх гофр за розмірами.
              </li>
              <li>
                Для пошуку хомута глушника введіть “хомут” – відобразиться
                список усіх хомутів за розмірами.
              </li>
              <li>
                Для пошуку паливних шлангів введіть внутрішній діаметр шланга в
                мм, додавши “mm” або “мм” (наприклад, "8 mm" або "8мм").
                Виберіть бренд <strong>"Розмір (Шланги)"</strong>. Важливо: один
                шматок шлангу дорівнює 10 сантиметрам.
              </li>
              <li>
                Для пошуку масла введіть його в'язкість (наприклад, 10W30 або
                10W40) та літраж (з літерою "L" або "л"). Наприклад,{' '}
                <strong>5W30 1 L</strong> або <strong>5W30 4 L</strong>. Бренд
                вибирається як <strong>"Тип (Масла)"</strong>.
              </li>
              <li>Для пошуку буксирувального троса введіть “Трос”.</li>
              <li>
                Для пошуку двірників введіть їх розмір у міліметрах з додаванням
                “mm” або “мм”. Далі виберіть бренд:
                <ul>
                  <li>
                    <strong>"Розмір (Двірник Б)"</strong> – якщо двірник
                    безкаркасний;
                  </li>
                  <li>
                    <strong>"Розмір (Двірник К)"</strong> – якщо двірник
                    каркасний.
                  </li>
                </ul>
              </li>
              <li>Для пошуку освіжувача повітря введіть “вонючка”.</li>
              <li>
                Для пошуку антифризу введіть потрібний колір (наприклад,
                зелений, красний, синій, жовтий, сиреневий/фіолетовий).
              </li>
              <li>
                Для пошуку засобу для очищення двигуна введіть DIMER або DIPER.
              </li>
              <li>Для пошуку рамки для номерів введіть “Рамка”.</li>
              <li>Для пошуку аптечки введіть “Аптечка”.</li>
              <li>Для пошуку світловідбивного жилету введіть “Жилет”.</li>
              <li>Для пошуку скребка або щітки введіть “Скребок”.</li>
              <li>Для пошуку вогнегасника введіть “Огретушитель”.</li>
              <li>Для пошуку домкрата введіть “Домкрат”.</li>
              <li>
                Для пошуку омивача скла:
                <ul>
                  <li>Літній – введіть “Омыватель лето”;</li>
                  <li>Зимовий – введіть “Омыватель зима”;</li>
                  <li>Або просто “Омыватель” та виберіть потрібний.</li>
                </ul>
              </li>
              <li>Для пошуку батарейки введіть “Батарейка”.</li>
              <li>Для пошуку пластикових хомутів (стяжок) введіть “Стяжка”.</li>
              <li>
                Деякі пильовики ШРУСів можна шукати за розмірами. Для цього
                введіть діаметр маленького отвору, потім через "x" – більшого, а
                потім висоту пильовика через "x" (наприклад, 24х90х90 або
                24x90).
              </li>
              <li>
                Деякі сальники, ролики та підшипники можна шукати за розмірами,
                вводячи розмір меншого отвору, потім більшого і товщину
                (наприклад, 32x47x10 або 32x47).
              </li>
              <li>Для пошуку присадок у масло введіть “WY 50672”.</li>
              <li>
                Для пошуку герметиків для систем охолодження введіть “Stop
                Leak”.
              </li>
              <li>Для пошуку моторного герметика введіть “Герметик”.</li>
              <li>Для пошуку герметиків для шин введіть “Герметик для шин”.</li>
              <li>
                Для пошуку епоксидного клею або холодних зварок введіть
                “Епоксид”.
              </li>
              <li>Для пошуку пасти для рук введіть “Паста”.</li>
              <li>Для пошуку ревіталізантів введіть “Ревіталізант”.</li>
              <li>Для пошуку штангенциркуля введіть “Штангенциркуль”.</li>
              <li>
                Для пошуку деяких лампочок введіть тип лампочки (наприклад, W5W,
                D2S, H7) та виберіть бренд <strong>"Лампочки (тип)"</strong>.
              </li>
              <li>Для пошуку стремянки ресор введіть “Стремянка”.</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  )
}

export default ModalInstruction
