import React, { useState } from 'react'
import { ImArrowRight } from 'react-icons/im'
import { ImArrowLeft } from 'react-icons/im'
import { ImCross } from 'react-icons/im'
import styles from './ModalPaymentHistory.module.css'

import Loader from '../../loader/Loader'
import { useGetClientPaymentHistoryQuery } from '../../../redux/clientData/apiClientData'

const ModalPaymentHistory = ({ setIsShowPaymentHistory }) => {
  const { data: clientPaymentHistory, isLoading: isLoadingPaymentHistory } =
    useGetClientPaymentHistoryQuery()

  const paymentHistory = clientPaymentHistory?.paymentHistory.filter(
    (payment) => payment.amount
  )

  const [currentPage, setCurrentPage] = useState(1)

  const itemsPerPage = 10

  const totalItems = paymentHistory?.length
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const lastItemIndex = currentPage * itemsPerPage
  const firstItemIndex = lastItemIndex - itemsPerPage
  const currentItems = paymentHistory?.slice(firstItemIndex, lastItemIndex)

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
  }

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  if (isLoadingPaymentHistory) return <Loader />

  return (
    <div
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          setIsShowPaymentHistory(false)
        }
      }}
      className="overlayCont"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={styles.modalContainer}
      >
        <div className={styles.modalHeader}>
          <div>Історія оплат</div>
          <div
            onClick={() => setIsShowPaymentHistory(false)}
            className={styles.closeIcon}
          >
            <ImCross />
          </div>
        </div>
        {paymentHistory.length === 0 ? (
          <div className={styles.noPaymentHistory}>
            <p>Історія оплат відсутня...</p>
          </div>
        ) : (
          <>
            <table className={styles.historyPaymentTable}>
              <thead>
                <tr>
                  <th>Сума</th>
                  <th>Дата</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((payment, i) => (
                  <tr key={i}>
                    <td>{payment.amount}</td>
                    <td>{payment.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <hr className={styles.modalHR} />
            <div className={styles.infoBtnContainer}>
              <button
                disabled={currentPage === 1}
                onClick={goToPreviousPage}
                className={styles.arrowBtn}
              >
                <ImArrowLeft />
              </button>
              {currentPage} з {totalPages}
              <button
                disabled={currentPage === totalPages}
                onClick={goToNextPage}
                className={styles.arrowBtn}
              >
                <ImArrowRight />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ModalPaymentHistory
