import { apiSlice } from '../../api/apiSlice'

const apiSearchSpares = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    searchSparesBrand: builder.mutation({
      query: (body) => ({
        url: `/search/brand`,
        method: 'POST',
        body,
      }),
    }),
    searchSparesByAnalogueID: builder.mutation({
      query: (body) => ({
        url: `/search/analogue-id`,
        method: 'POST',
        body,
      }),
    }),
    searchSparesBySpareID: builder.mutation({
      query: (body) => ({
        url: `/search/internal`,
        method: 'POST',
        body,
      }),
    }),
    searchSparesByExternalCatalogue: builder.mutation({
      query: (body) => ({
        url: `/search/external`,
        method: 'POST',
        body,
      }),
    }),
    orderSpareAvailability: builder.mutation({
      query: (body) => ({
        url: `/search/reserve`,
        method: 'POST',
        body,
      }),
    }),
    getDeliveryTimeDetails: builder.mutation({
      query: (body) => ({
        url: `/search/delivery-info`,
        method: 'POST',
        body,
      }),
    }),
    getSparesByDescription: builder.mutation({
      query: (body) => ({
        url: `/search/by-description`,
        method: 'POST',
        body,
      }),
    }),
    getSparesByBrandCode: builder.mutation({
      query: (body) => ({
        url: `/search/by-brandcode`,
        method: 'POST',
        body,
      }),
    }),
    getSparesImage: builder.mutation({
      query: (body) => ({
        url: `/search/image`,
        method: 'POST',
        body,
      }),
    }),
    getSparesImageBulk: builder.mutation({
      query: (body) => ({
        url: `/search/image-bulk`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useSearchSparesBrandMutation,
  useSearchSparesByAnalogueIDMutation,
  useSearchSparesByExternalCatalogueMutation,
  useSearchSparesBySpareIDMutation,
  useGetDeliveryTimeDetailsMutation,
  useOrderSpareAvailabilityMutation,
  useGetSparesByDescriptionMutation,
  useGetSparesByBrandCodeMutation,
  useGetSparesImageMutation,
  useGetSparesImageBulkMutation,
} = apiSearchSpares
